/*white*/
/*gold*/
/*grey*/
/*brown-gold*/
/*gold line*/
.qr {
  position: relative;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
}

.qr::before {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 1122px;
  height: 100%;
  left: 50%;
  top: 0%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  background-image: url("../images/restaurantBackground.svg");
  background-size: calc(max(100%, 1200px)) 100%;
  background-position: center;
  z-index: -1;
}

.qr .logo {
  position: relative;
  width: 100%;
  height: 240px;
  background-image: url("../images/LogoRed.webp");
  background-position: center;
  background-size: 180px auto;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.qr .logo a {
  position: absolute;
  width: 140px;
  height: 166px;
  top: calc(50% + 20px);
  left: calc(50% + 4px);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: 600;
  color: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.qr .cartas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 10px;
}

.qr .cartas .carta {
  position: relative;
  width: calc(min(320px, 100%));
  height: 220px;
  padding: 18px;
  background-color: #171c20;
  border-radius: 12px;
  -webkit-box-shadow: 0px 2px 6px 1px #080808;
          box-shadow: 0px 2px 6px 1px #080808;
}

.qr .cartas .carta .carta-bg {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 28px);
  height: 100%;
  margin: auto;
  border: 2px solid #c9ab81;
  padding: 14px 2px 16px 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  opacity: 0.7;
  z-index: 1;
}

.qr .cartas .carta .carta-bg::after {
  content: '';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  width: calc(100% + 32px);
  height: calc(100% - 24px);
  border: 2px solid #c9ab81;
  top: 12px;
  left: -16px;
  z-index: -1;
}

.qr .cartas .carta .carta-bg .carta-title {
  color: #ead1af;
  font-size: 54px;
  font-family: 'Italianno';
  line-height: 54px;
  margin-bottom: -28px;
}

.qr .cartas .carta .carta-bg .carta-button {
  height: 50px;
  width: 160px;
  margin: 0px auto;
  border: solid 2px #c9ab81;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ead1af;
  font-weight: 600;
}

.qr .cartas .carta .carta-bg .carta-button:hover {
  cursor: pointer;
}

.qr .cartas .carta .carta-bg .carta-button a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
}

.qr .copy {
  font-weight: 600;
  color: #c3c3c3;
  padding: 40px 20px 20px;
}

@media (max-width: 330px) {
  .qr .carta-title {
    font-size: 48px !important;
  }
}
