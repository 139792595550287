/*white*/
/*gold*/
/*grey*/
/*brown-gold*/
/*gold line*/
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@font-face {
  font-family: 'Afterglow';
  src: local("Afterglow"), url("../../public/Fonts/Afterglow.ttf") format("truetype");
  font-display: swap;
}

a {
  all: unset;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
  background-color: #121518;
  width: 100%;
  min-height: 100vh;
}

.grey {
  color: #888;
}

.font1 {
  padding: 12px;
  font-size: 40px;
  font-family: 'Italianno';
}

.font2 {
  padding: 12px;
  padding-bottom: 16px;
  font-size: 72px;
  line-height: 1em;
  font-family: 'Italiana';
}

.font3 {
  padding: 12px;
  font-size: 16px;
}

.font4 {
  padding: 12px;
  font-size: 15px;
}
