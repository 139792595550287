/*white*/
/*gold*/
/*grey*/
/*brown-gold*/
/*gold line*/
.main-restaurant .bg-wrapper,
.main-menu .bg-wrapper {
  position: relative;
  z-index: 1;
}

.main-restaurant .bg-wrapper::before,
.main-menu .bg-wrapper::before {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 1122px;
  height: 100%;
  height: calc(100% - 34px);
  left: 50%;
  top: 0%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  opacity: 0.7;
  background-image: url("../images/restaurantBackground.svg");
  background-size: calc(max(100%, 800px)) 100%;
  z-index: -1;
}

.main-restaurant .title-menu,
.main-menu .title-menu {
  position: relative;
  height: 460px;
  width: 100%;
  overflow: hidden;
}

.main-restaurant .title-menu .letter,
.main-menu .title-menu .letter {
  position: absolute;
  height: 160px;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  color: #eee;
  font-size: 40px;
  font-family: 'Italiana';
}

.main-restaurant .title-menu .logo,
.main-menu .title-menu .logo {
  position: absolute;
  height: 190px;
  width: 180px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -11%);
          transform: translate(-50%, -11%);
  background-image: url("../images/LogoRedIcon.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.main-restaurant .restaurant-title,
.main-menu .restaurant-title {
  padding: 0px 40px 20px;
  color: #c9ab81;
  font-size: 60px;
  font-family: 'Italianno';
  max-width: 800px;
  margin: auto;
}

.main-restaurant .menu-top,
.main-menu .menu-top {
  height: 100px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 200px auto;
      grid-template-columns: auto 200px auto;
  max-width: 1200px;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px 30px;
  margin: auto;
}

.main-restaurant .menu-top .line,
.main-menu .menu-top .line {
  height: 1px;
  width: 100%;
  background-color: #c9ab81;
}

.main-restaurant .menu-top .icon,
.main-menu .menu-top .icon {
  width: 54px;
  height: 54px;
  background-image: url("../images/LogoRedIcon.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
  opacity: 0.9;
}
